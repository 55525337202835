import store from 'src/redux/store';

import api, { demo } from '..';
import { fakeLogins } from './mockData';

const path = '/auth';

const login = async (data, role_id) => {
  switch (demo) {
    case true:
      return fakeLogins.find(({ user }) => user.role_id == role_id);
    default:
      return await api.post(`${path}/login`, data);
  }
};

const logout = async () => {
  switch (demo) {
    case true:
      return {};
    default:
      return await api.post(`${path}/logout`);
  }
};

const me = async () => {
  const { user: loggedInUser } = store.getState().auth;

  switch (demo) {
    case true:
      return fakeLogins.find(({ user }) => user.role_id == loggedInUser.role_id)
        ?.user;
    default:
      return await api.get(`users/me`);
  }
};

const refreshToken = (data) => api.post('/Login/RefreshToken', data);

const forgotPassword = (data) => api.post('/auth/forgot-password', data);

const resetPassword = (data) => api.post('/auth/reset-password', data);

export default {
  login,
  logout,
  me,
  refreshToken,
  forgotPassword,
  resetPassword,
};
