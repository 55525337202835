import { combineReducers, createStore } from 'redux';
import notifications from 'src/redux/reducers/notifications';
import loading from 'src/redux/reducers/loading';
import auth from 'src/redux/reducers/auth';

const store = createStore(
  combineReducers({ notifications, loading, auth }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
