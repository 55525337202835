import 'core-js';
import 'react-app-polyfill/stable';

import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from 'src/App';
import LoadingIndicator from 'src/components/LoadingIndicator';
import i18n from 'src/i18next';
import store from 'src/redux/store';
import reportWebVitals from 'src/reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Suspense fallback={<LoadingIndicator main />}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Suspense>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
