import { useDispatch, useSelector } from 'react-redux';
import { BaseDiv } from 'src/components/Base/Grid';
import {
  BaseToast,
  BaseToastBody,
  BaseToastClose,
  BaseToaster,
} from 'src/components/Base/Toaster';
import { removeNotification } from 'src/redux/actions/notifications';

const Toaster = () => {
  const { notifications } = useSelector((_) => _.notifications);
  const dispatch = useDispatch();

  return (
    <BaseToaster placement="bottom-center" key="toaster" className="mb-5">
      {notifications?.map(({ msg, type }, i) => (
        <BaseToast
          key={i}
          autohide
          color={type === 'err' ? 'danger' : 'success'}
          className="text-white align-items-center p-3"
          visible
          delay={7000}
          onClose={() => dispatch(removeNotification(i))}
        >
          <BaseDiv className="d-flex">
            <BaseToastBody>{msg}</BaseToastBody>

            <BaseToastClose className="me-2 m-auto" white />
          </BaseDiv>
        </BaseToast>
      ))}
    </BaseToaster>
  );
};

export default Toaster;
