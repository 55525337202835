import { CRow } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';

const BaseRow = React.memo(
  ({ children, className, noAlign, justify, ...rest }) => {
    return (
      <CRow
        className={`w-100 mx-0 ${!noAlign && 'align-items-center'} ${
          justify && 'justify-content-center'
        } ${className}`}
        {...rest}
      >
        {children}
      </CRow>
    );
  },
);

BaseRow.propTypes = {
  className: PropTypes.string,
  noAlign: PropTypes.bool,
};

export default BaseRow;
