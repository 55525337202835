import {
  BarElement,
  CategoryScale,
  Chart as ChartJs,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js/auto';
import Annotation from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-vis/dist/style.css';
import LoadingIndicator from 'src/components/LoadingIndicator';
import NotificationsToaster from 'src/components/Toaster';
import 'src/scss/style.scss';

ChartJs.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Annotation,
  ChartDataLabels,
);

// Layouts
const AuthLayout = lazy(() => import('src/layout/Auth/Global'));
const PublicLayout = lazy(() => import('src/layout/Public'));

const App = () => {
  const { i18n } = useTranslation();
  const { token } = useSelector((_) => _.auth);

  document.dir = i18n.dir();
  document.lang = i18n.language;

  return (
    <BrowserRouter>
      <NotificationsToaster />

      <Suspense fallback={<LoadingIndicator main />}>
        <Routes>
          {token ? (
            <Route path="*" name="Home" element={<AuthLayout />} />
          ) : (
            <Route path="*" name="Home" element={<PublicLayout />} />
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
