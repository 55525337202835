import UserAPI from 'src/api/user';

const auth = (
  state = {
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    current_semester: localStorage.getItem('current_semester')?.length
      ? JSON.parse(localStorage.getItem('current_semester'))
      : {},
    user: localStorage.getItem('user')?.length
      ? JSON.parse(localStorage.getItem('user'))
      : {},
  },
  action,
) => {
  switch (action.type) {
    case 'login': {
      localStorage.setItem('token', action.resp.jwt);
      localStorage.setItem('refreshToken', action.resp.refreshToken);
      localStorage.setItem(
        'current_semester',
        JSON.stringify(action.resp.current_semester),
      );
      localStorage.setItem('user', JSON.stringify(action.resp.user));

      return {
        token: action.resp.token,
        refreshToken: action.resp.refreshToken,
        current_semester: action.resp.current_semester,
        user: action.resp.user,
      };
    }

    case 'logout': {
      UserAPI.logout()
        .then((res) => console.log({ res }))
        .catch((err) => console.log({ err }))
        .finally(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('current_semester');
          localStorage.removeItem('user');

          window.location.href = '/';

          return {
            token: null,
            refreshToken: null,
            current_semester: {},
            user: {},
          };
        });
    }

    case 'updateUser': {
      const updatedUser = { ...state.user, ...action.data };
      localStorage.setItem('user', JSON.stringify(updatedUser));

      return {
        ...state,
        user: updatedUser,
      };
    }

    default:
      return state;
  }
};

export default auth;
