import axios from 'axios';
import { logout } from 'src/redux/actions/auth';
import { endLoading, startLoading } from 'src/redux/actions/loading';
import { addNotification } from 'src/redux/actions/notifications';
import store from 'src/redux/store';

import { t } from 'i18next';

export const baseURL =
  (process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:8000') + '/api';

const service = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-school-name': process.env.REACT_APP_SCHOOL_NAME || 'demo_school',
  },
});

service.interceptors.request.use(
  (config) => {
    store.dispatch(startLoading());
    const { token } = store.getState().auth;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// const unauthErrorHandle = async (res) => {
//   console.log({ res });
//   const originalConfig = res.config;

//   console.log({ originalConfig });

//   if (res?.status === 403 && !originalConfig._retry) {
//     originalConfig._retry = true;

//     console.log(originalConfig._retry);

//     try {
//       store.dispatch(startLoading());
//       const { refreshToken } = store.getState().auth;
//       const resp = await UserAPI.refreshToken({ refreshToken });
//       console.log({ resp });

//       const {
//         access_token,
//         refresh_token,
//         userFullNameAr,
//         userFullNameEn,
//         birthDate,
//       } = resp.data;

//       store.dispatch(
//         login({
//           jwt: access_token,
//           refreshToken: refresh_token,
//           user: { ...userFullNameAr, ...userFullNameEn, birthDate },
//         }),
//       );
//       return service(originalConfig);
//     } catch (err) {
//       console.log({ err });
//       store.dispatch(logout());
//       window.location.href = '/';
//     }
//   } else {
//     console.log({ res });
//     store.dispatch(logout());
//     window.location.href = '/';
//     // store.dispatch(addNotification({ error: true, action: "Executed", message: i18n.t("This is Unauthorized Access") }));
//     // return Promise.reject("Your session has expired. Please sign in again to continue.");
//   }
// };

const errorHandle = (res) => {
  if (res?.status == 401 || res?.status == 403) {
    // unauthErrorHandle(res);
    store.dispatch(logout());
  }

  const msg = res?.data?.message || t('Global.Errors.Something Went Wrong');

  store.dispatch(
    addNotification({
      type: 'err',
      msg: msg,
    }),
  );

  return Promise.reject(msg);
};

service.interceptors.response.use(
  (res) => {
    store.dispatch(endLoading());

    if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
      return res.data;
    }

    return errorHandle(res);
  },
  (err) => {
    store.dispatch(endLoading());
    return errorHandle(err.response);
  },
);

export default service;

export const demo = process.env.REACT_APP_DEMO_MODE == 'true';
