const adminLoginMockData = {
  user: {
    id: 1,
    school_no: 123321,
    role_id: 1,
    status_id: 1,
    local_name: 'محمد علي',
    english_name: 'Mohammad Ali',
    email: 'semesteer@hotmail.com',
    leave_date: null,
    contact: {
      id: 31,
      relative_id: 31,
      relative_relationship: 'Friend',
      birthdate: '2015-03-04',
      gender: 'Male',
      address: '326 شارع الجامعة, السلط',
      nationality: 'Jordan',
      passport_no: 'JOR1543',
      passport_expiry_date: '2026-07-06',
      visa_expiry_date: null,
      phone: '+962792955652',
      email: 'Mohammad.Ali@gmail.com',
      photo: null,
      passport: null,
      visa: null,
      doc1: null,
      doc2: null,
      doc3: null,
      relative: {
        id: 31,
        relative_name: 'عمار نصرت',
        relative_english_name: 'Ammar Ali Hasan Nasrat',
        relative_local_name: 'عمار علي حسن نصرت',
        relative_gender: 'Male',
        relative_job: 'محاسب',
        relative_email: 'Ammar.Nasrat@outlook.com',
        relative_phone: '+962794579137',
        relative_home_address: '326 شارع الجامعة, السلط',
        relative_organization: 'المؤسسة العامة X',
        relative_work_address: '324 شارع مكة, السلط',
        relative_extra_data: '+962796907218',
        relative_nationality: 'Qatar',
        relative_passport_no: 'wth3973',
        relative_passport_expiry_date: '2025-09-09',
        relative_visa_expiry_date: '2024-07-26',
        relative_visa_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CE4.tmp',
        relative_passport_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CD4.tmp',
      },
    },
    staff: {
      id: 1,
      english_name: 'Mohammad Ali Yassser Alam Eddien',
      local_name: 'محمد علي ياسر علم الدين',
    },
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

const princiaplLoginMockData = {
  user: {
    id: 2,
    school_no: 123321,
    role_id: 2,
    status_id: 1,
    local_name: 'جلال عبدالله',
    english_name: 'Jalal Abdulla',
    email: 'semesteer@hotmail.com',
    leave_date: null,
    contact: {
      id: 31,
      relative_id: 31,
      relative_relationship: 'Friend',
      birthdate: '2015-03-04',
      gender: 'Male',
      address: '326 شارع الجامعة, السلط',
      nationality: 'Jordan',
      passport_no: 'JOR1543',
      passport_expiry_date: '2026-07-06',
      visa_expiry_date: null,
      phone: '+962792955652',
      email: 'Jalal.Abdulla@gmail.com',
      photo: null,
      passport: null,
      visa: null,
      doc1: null,
      doc2: null,
      doc3: null,
      relative: {
        id: 31,
        relative_name: 'عمار نصرت',
        relative_english_name: 'Ammar Ali Hasan Nasrat',
        relative_local_name: 'عمار علي حسن نصرت',
        relative_gender: 'Male',
        relative_job: 'محاسب',
        relative_email: 'Ammar.Nasrat@outlook.com',
        relative_phone: '+962794579137',
        relative_home_address: '326 شارع الجامعة, السلط',
        relative_organization: 'المؤسسة العامة X',
        relative_work_address: '324 شارع مكة, السلط',
        relative_extra_data: '+962796907218',
        relative_nationality: 'Qatar',
        relative_passport_no: 'wth3973',
        relative_passport_expiry_date: '2025-09-09',
        relative_visa_expiry_date: '2024-07-26',
        relative_visa_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CE4.tmp',
        relative_passport_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CD4.tmp',
      },
    },
    staff: {
      id: 1,
      english_name: 'Jalal Naser Rabee Abdulla',
      local_name: 'جلال ناصر ربيع عبدالله',
    },
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

const vicePrinciaplLoginMockData = {
  user: {
    id: 3,
    school_no: 123321,
    role_id: 3,
    status_id: 1,
    local_name: 'يسرى فتحي',
    english_name: 'Yusra Fathi',
    email: 'semesteer@hotmail.com',
    leave_date: null,
    contact: {
      id: 31,
      relative_id: 31,
      relative_relationship: 'Friend',
      birthdate: '2015-03-04',
      gender: 'Female',
      address: '326 شارع الجامعة, السلط',
      nationality: 'Jordan',
      passport_no: 'JOR1543',
      passport_expiry_date: '2026-07-06',
      visa_expiry_date: null,
      phone: '+962792955652',
      email: 'Yusra.Fathi@gmail.com',
      photo: null,
      passport: null,
      visa: null,
      doc1: null,
      doc2: null,
      doc3: null,
      relative: {
        id: 31,
        relative_name: 'عمار نصرت',
        relative_english_name: 'Ammar Ali Hasan Nasrat',
        relative_local_name: 'عمار علي حسن نصرت',
        relative_gender: 'Male',
        relative_job: 'محاسب',
        relative_email: 'Ammar.Nasrat@outlook.com',
        relative_phone: '+962794579137',
        relative_home_address: '326 شارع الجامعة, السلط',
        relative_organization: 'المؤسسة العامة X',
        relative_work_address: '324 شارع مكة, السلط',
        relative_extra_data: '+962796907218',
        relative_nationality: 'Qatar',
        relative_passport_no: 'wth3973',
        relative_passport_expiry_date: '2025-09-09',
        relative_visa_expiry_date: '2024-07-26',
        relative_visa_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CE4.tmp',
        relative_passport_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CD4.tmp',
      },
    },
    staff: {
      id: 1,
      english_name: 'Yusra Adel Salem Fathi',
      local_name: 'يسرى عادل سالم فتحي',
    },
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

const secreatryLoginMockData = {
  user: {
    id: 4,
    school_no: 123321,
    role_id: 4,
    status_id: 1,
    local_name: 'جميلة خالد',
    english_name: 'Jamila Khaled',
    email: 'semesteer@hotmail.com',
    leave_date: null,
    contact: {
      id: 31,
      relative_id: 31,
      relative_relationship: 'Friend',
      birthdate: '2015-03-04',
      gender: 'Female',
      address: '326 شارع الجامعة, السلط',
      nationality: 'Jordan',
      passport_no: 'JOR1543',
      passport_expiry_date: '2026-07-06',
      visa_expiry_date: null,
      phone: '+962792955652',
      email: 'Jamila.Khaled@gmail.com',
      photo: null,
      passport: null,
      visa: null,
      doc1: null,
      doc2: null,
      doc3: null,
      relative: {
        id: 31,
        relative_name: 'عمار نصرت',
        relative_english_name: 'Ammar Ali Hasan Nasrat',
        relative_local_name: 'عمار علي حسن نصرت',
        relative_gender: 'Male',
        relative_job: 'محاسب',
        relative_email: 'Ammar.Nasrat@outlook.com',
        relative_phone: '+962794579137',
        relative_home_address: '326 شارع الجامعة, السلط',
        relative_organization: 'المؤسسة العامة X',
        relative_work_address: '324 شارع مكة, السلط',
        relative_extra_data: '+962796907218',
        relative_nationality: 'Qatar',
        relative_passport_no: 'wth3973',
        relative_passport_expiry_date: '2025-09-09',
        relative_visa_expiry_date: '2024-07-26',
        relative_visa_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CE4.tmp',
        relative_passport_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CD4.tmp',
      },
    },
    staff: {
      id: 1,
      english_name: 'Jamila Ahmad Abdulla Khaled',
      local_name: 'جميلة أحمد عبدالله خالد',
    },
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

const accountantLoginMockData = {
  user: {
    id: 5,
    school_no: 123321,
    role_id: 5,
    status_id: 1,
    local_name: 'هناء نصر',
    english_name: 'Hanaa Nasr',
    email: 'semesteer@hotmail.com',
    leave_date: null,
    contact: {
      id: 31,
      relative_id: 31,
      relative_relationship: 'Friend',
      birthdate: '2015-03-04',
      gender: 'Female',
      address: '326 شارع الجامعة, السلط',
      nationality: 'Jordan',
      passport_no: 'JOR1543',
      passport_expiry_date: '2026-07-06',
      visa_expiry_date: null,
      phone: '+962792955652',
      email: 'Hanaa.Nasr@gmail.com',
      photo: null,
      passport: null,
      visa: null,
      doc1: null,
      doc2: null,
      doc3: null,
      relative: {
        id: 31,
        relative_name: 'عمار نصرت',
        relative_english_name: 'Ammar Ali Hasan Nasrat',
        relative_local_name: 'عمار علي حسن نصرت',
        relative_gender: 'Male',
        relative_job: 'محاسب',
        relative_email: 'Ammar.Nasrat@outlook.com',
        relative_phone: '+962794579137',
        relative_home_address: '326 شارع الجامعة, السلط',
        relative_organization: 'المؤسسة العامة X',
        relative_work_address: '324 شارع مكة, السلط',
        relative_extra_data: '+962796907218',
        relative_nationality: 'Qatar',
        relative_passport_no: 'wth3973',
        relative_passport_expiry_date: '2025-09-09',
        relative_visa_expiry_date: '2024-07-26',
        relative_visa_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CE4.tmp',
        relative_passport_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CD4.tmp',
      },
    },
    staff: {
      id: 1,
      english_name: 'Hanaa Jad-Allah Akram Nasr',
      local_name: 'هناء جاد الله أكرم نصر',
    },
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

const teacherLoginMockData = {
  user: {
    id: 6,
    school_no: 123321,
    role_id: 6,
    status_id: 1,
    local_name: 'محمد يوسف',
    english_name: 'Mohammad Yusef',
    email: 'semesteer@hotmail.com',
    leave_date: null,
    contact: {
      id: 31,
      relative_id: 31,
      relative_relationship: 'Friend',
      birthdate: '2015-03-04',
      gender: 'Male',
      address: '326 شارع الجامعة, السلط',
      nationality: 'Jordan',
      passport_no: 'JOR1543',
      passport_expiry_date: '2026-07-06',
      visa_expiry_date: null,
      phone: '+962792955652',
      email: 'Mohammad.Yusef@gmail.com',
      photo: null,
      passport: null,
      visa: null,
      doc1: null,
      doc2: null,
      doc3: null,
      relative: {
        id: 31,
        relative_name: 'عمار نصرت',
        relative_english_name: 'Ammar Ali Hasan Nasrat',
        relative_local_name: 'عمار علي حسن نصرت',
        relative_gender: 'Male',
        relative_job: 'محاسب',
        relative_email: 'Ammar.Nasrat@outlook.com',
        relative_phone: '+962794579137',
        relative_home_address: '326 شارع الجامعة, السلط',
        relative_organization: 'المؤسسة العامة X',
        relative_work_address: '324 شارع مكة, السلط',
        relative_extra_data: '+962796907218',
        relative_nationality: 'Qatar',
        relative_passport_no: 'wth3973',
        relative_passport_expiry_date: '2025-09-09',
        relative_visa_expiry_date: '2024-07-26',
        relative_visa_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CE4.tmp',
        relative_passport_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CD4.tmp',
      },
    },
    staff: {
      id: 1,
      english_name: 'Mohammad Khalil Abdulla Yusef',
      local_name: 'محمد خليل عبدالله يوسف',
    },
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

const studentLoginMockData = {
  user: {
    id: 7,
    school_no: 123321,
    role_id: 7,
    status_id: 1,
    local_name: 'جمال علي',
    english_name: 'Jamal Ali',
    email: 'semesteer@hotmail.com',
    leave_date: null,
    contact: {
      id: 31,
      relative_id: 31,
      relative_relationship: 'Friend',
      birthdate: '2015-03-04',
      gender: 'Female',
      address: '326 شارع الجامعة, السلط',
      nationality: 'Jordan',
      passport_no: 'JOR1543',
      passport_expiry_date: '2026-07-06',
      visa_expiry_date: null,
      phone: '+962792955652',
      email: 'Jamal.Ali@gmail.com',
      photo: null,
      passport: null,
      visa: null,
      doc1: null,
      doc2: null,
      doc3: null,
      relative: {
        id: 31,
        relative_name: 'عمار نصرت',
        relative_english_name: 'Ammar Ali Hasan Nasrat',
        relative_local_name: 'عمار علي حسن نصرت',
        relative_gender: 'Male',
        relative_job: 'محاسب',
        relative_email: 'Ammar.Nasrat@outlook.com',
        relative_phone: '+962794579137',
        relative_home_address: '326 شارع الجامعة, السلط',
        relative_organization: 'المؤسسة العامة X',
        relative_work_address: '324 شارع مكة, السلط',
        relative_extra_data: '+962796907218',
        relative_nationality: 'Qatar',
        relative_passport_no: 'wth3973',
        relative_passport_expiry_date: '2025-09-09',
        relative_visa_expiry_date: '2024-07-26',
        relative_visa_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CE4.tmp',
        relative_passport_photo:
          'C:\\Users\\Makkahwi\\AppData\\Local\\Temp\\fak6CD4.tmp',
      },
    },
    student: {
      id: 1,
      english_name: 'Jamal Abdul Rahem Amer Ali',
      local_name: 'جمال عبدالرحيم عامر علي',
      classroom_id: 4,
      sponsor_name: 'المؤسسة س',
      school_bus_transportation: 1,
      tuition_fees_per_semester: 1,
      financial_block: 0,
    },
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

const applicantLoginMockData = {
  user: {
    id: 7,
    school_no: 123321,
    role_id: 8,
    status_id: 1,
    local_name: 'جمال علي',
    english_name: 'Jamal Ali',
    email: 'semesteer@hotmail.com',
    leave_date: null,
  },
  current_semester: {
    id: 1,
    title: '1',
    year_id: 1,
    start_date: '2023-08-20',
    join_deadline_date: '2023-09-20',
    results_date: '2024-01-15',
    end_date: '2024-01-20',
    year: {
      id: 1,
      title: '2023 - 2024',
    },
  },
  token: 'thisIsFakeToken',
};

export const fakeLogins = [
  adminLoginMockData,
  princiaplLoginMockData,
  vicePrinciaplLoginMockData,
  secreatryLoginMockData,
  accountantLoginMockData,
  teacherLoginMockData,
  studentLoginMockData,
  applicantLoginMockData,
];
