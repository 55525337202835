import PropTypes from 'prop-types';
import React from 'react';
import { BaseContainer } from 'src/components/Base/Grid';
import { BaseSpinner } from 'src/components/Base/Loader';

const LoadingIndicator = React.memo(({ type, color, main, className }) => {
  const loader = () => {
    switch (type) {
      case 'spinner':
        return <BaseSpinner color={color || 'white'} />;
      default:
        return <BaseSpinner color={color || 'white'} />;
    }
  };

  return (
    <BaseContainer
      className={`text-center py-5 ${
        main ? 'vw-100 vh-100 align-items-center' : ''
      } ${className}`}
    >
      {loader()}
    </BaseContainer>
  );
});

LoadingIndicator.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  main: PropTypes.bool,
  className: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  type: 'spinner',
};

export default LoadingIndicator;
